import NuxtDeviceIsMixin from  "\u002Fapp\u002Fnode_modules\u002Fnuxt-device-is\u002Flib\u002Fmodule.mixin.js";
import {getDeviceFromUa, getDeviceFromWs, isSafari} from  "\u002Fapp\u002Fnode_modules\u002Fnuxt-device-is\u002Flib\u002Fmodule.helpers.js";

let options = {"breakpoints":{"mobile":767,"tablet":1279,"laptop":1439,"desktop":999999},"mixin_path":"\u002Fapp\u002Fnode_modules\u002Fnuxt-device-is\u002Flib\u002Fmodule.mixin.js","helpers_path":"\u002Fapp\u002Fnode_modules\u002Fnuxt-device-is\u002Flib\u002Fmodule.helpers.js"};

let defaultBreakpoints = {
    mobile: 720,
    tablet: 1024,
    laptop: 1440,
    desktop: 999999
};

let breakpoints = options && options.breakpoints ? {...options.breakpoints} : {...defaultBreakpoints};

function getObservableElement(ua) {
    return isSafari(ua) ? document.documentElement.clientWidth : window.innerWidth;
}
function addClientUpdate(onUpdate) {
    window.addEventListener('resize', function () {
        onUpdate(getDeviceFromWs(getObservableElement(navigator.userAgent), breakpoints));
    });
}

export default function (ctx, inject) {
    let deviceIs = ctx.$deviceIs ? ctx.$deviceIs : NuxtDeviceIsMixin();

    if (ctx && ctx.req && ctx.req.headers) {
        deviceIs.update(getDeviceFromUa(ctx.req.headers['user-agent']));
    } else {
        deviceIs.update(getDeviceFromWs(getObservableElement(navigator.userAgent), breakpoints));
        addClientUpdate(deviceIs.update);
    }

    if (!ctx.$deviceIs) {
        ctx.$deviceIs = deviceIs;
        inject('deviceIs', deviceIs);
    }
}
