import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _246ac10e = () => interopDefault(import('../landing/pages/developers/index.vue' /* webpackChunkName: "pages/developers/index" */))
const _13ac06a9 = () => interopDefault(import('../landing/pages/legal-information/index.vue' /* webpackChunkName: "pages/legal-information/index" */))
const _5fc8d188 = () => interopDefault(import('../landing/pages/documents/archive/index.vue' /* webpackChunkName: "pages/documents/archive/index" */))
const _c7ab1540 = () => interopDefault(import('../landing/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _64812878 = () => interopDefault(import('../landing/pages/documents/_slug/index.vue' /* webpackChunkName: "pages/documents/_slug/index" */))
const _019ad529 = () => interopDefault(import('../landing/pages/events/_id/index.vue' /* webpackChunkName: "pages/events/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: '_active-link',
  linkExactActiveClass: '_exact-link',
  scrollBehavior,

  routes: [{
    path: "/developers",
    component: _246ac10e,
    name: "developers"
  }, {
    path: "/legal-information",
    component: _13ac06a9,
    name: "legal-information"
  }, {
    path: "/documents/archive",
    component: _5fc8d188,
    name: "documents-archive"
  }, {
    path: "/",
    component: _c7ab1540,
    name: "index"
  }, {
    path: "/documents/:slug",
    component: _64812878,
    name: "documents-slug"
  }, {
    path: "/events/:id",
    component: _019ad529,
    name: "events-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
