import hydrationRace from './hydrationRace.mjs'

const injectDelayHydrationApi = (ctx, inject) => {
  inject('delayHydration', {
    hydrationRace,

  config: {"mode":"manual","hydrateOnEvents":["mousemove","scroll","keydown","click","touchstart","wheel"],"postIdleTimeout":{"mobile":10000,"desktop":9000},"idleCallbackTimeout":7000,"forever":false,"debug":false,"replayClick":false,"replayClickMaxEventAge":1000}
  })
}

export default injectDelayHydrationApi
