import Vue from 'vue'
import { createImage} from '~image'
import NuxtImg from '~image/components/nuxt-img.vue'
import NuxtPicture from '~image/components/nuxt-picture.vue'

import * as staticRuntime$34c2 from '/app/node_modules/@nuxt/image/dist/runtime/providers/static.js'
import * as imgProxyRuntime$6a2f from '/app/landing/config/imgProxy.js'

const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "mobile": 767,
    "tablet": 1279,
    "laptop": 1439,
    "desktop": 1920
  },
  "presets": {
    "preview": {
      "modifiers": {
        "quality": 20,
        "blur": 30
      }
    }
  },
  "provider": "imgProxy",
  "domains": [
    "dvizh.idacloud.ru",
    "storage.yandexcloud.net"
  ],
  "alias": {}
}

imageOptions.providers = {
  ['static']: { provider: staticRuntime$34c2, defaults: {} },
  ['imgProxy']: { provider: imgProxyRuntime$6a2f, defaults: {"baseURL":"https://dvizh.idacloud.ru/proxy","staticFolder":"/images","quality":80} }
}

Vue.component(NuxtImg.name, NuxtImg)
Vue.component(NuxtPicture.name, NuxtPicture)
Vue.component('NImg', NuxtImg)
Vue.component('NPicture', NuxtPicture)

export default function (nuxtContext, inject) {
  const $img = createImage(imageOptions, nuxtContext)

  if (process.static && process.server) {
    nuxtContext.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._img = nuxtState._img || {}
    })
  }

  inject('img', $img)
}
